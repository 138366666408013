<template>
  <div id="favorites_page">
      <div class="container">

        <!-- Page title -->
        <span class="page-title">Favorites</span>

        <el-row :gutter="20">
          <el-col v-for="(val, key) in favorites" :key="key" :sm="12" :lg="8">
            <el-card @click.native="openModal('edit')" class="favorite-mode-container"  :body-style="{ padding: '15px' }" shadow="always">
              <div class="favorite-mode">
                <div class="favorite-icon">
                  <img :src="icon(val.mode)" />
                </div>

                <div class="favorite-info">
                  <span class="name">{{ val.name }}</span>
                  <span class="other">{{ val.id }} - {{ val.other }}</span>
                </div>
              </div>
            </el-card>

          </el-col>

          <el-col :span="24" style="text-align: center; margin-top: 10px">
            <el-button @click="openModal('add')" type="primary" style="max-width: 550px" class="w100 secondary-color">Add to favorites</el-button>
          </el-col>
        </el-row>
      </div>

      <modal :title="modal.mode == 'add' ? 'Add to favorites' : 'Edit'" v-model="modal.modal_active">
        <div class="modal-info">
          <form-item label="Favorite type">
            <el-select v-model="modal.selected_type" placeholder="Type">
              <el-option label="Bus Stop" value="bus"></el-option>
              <el-option label="Dart Station" value="dart"></el-option>
              <el-option label="Luas Station" value="luas"></el-option>
            </el-select>
          </form-item>
        </div>
      </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal/Modal';
import FormItem from '@/components/Form/FormItem';

export default {
  components: { Modal, FormItem },
  data: () => ({
    modal: {
      modal_active: false,
      mode: 'add', /* add, edit */
      selected_key: null,
      selected_type: null,
      selected_name: '',
      selected_id: null,
    }
  }),

  methods: {
     icon(type, color = 'white') {
      return `${process.env.BASE_URL}img/activity-icons/${type}_${color}.png`;
    },

    openModal(mode) {
      this.modal.mode = mode;
      this.modal.modal_active = true;
    }
  },

  computed: {
    favorites() {
      return this.$store.getters.userFavorites;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/theme/variables.scss';

  .el-col {
    margin-bottom: 10px;
  }

  .favorite-mode {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .favorite-icon {
      width: 50px;
      height: 50px;
      margin-right: 15px;

      img {
        width: 100%;
      }
    }

    .favorite-info {
      
      .name {
        font-size: 20px;
        display: block;
      }

      .other {
        display: block;
      }
    }
  }

  .favorite-mode-container:active {
    border: 1px solid $secondary-color;
    border-radius: 3px;
  }
</style>