var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "favorites_page" } },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("span", { staticClass: "page-title" }, [_vm._v("Favorites")]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm._l(_vm.favorites, function(val, key) {
                return _c(
                  "el-col",
                  { key: key, attrs: { sm: 12, lg: 8 } },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "favorite-mode-container",
                        attrs: {
                          "body-style": { padding: "15px" },
                          shadow: "always"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.openModal("edit")
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "favorite-mode" }, [
                          _c("div", { staticClass: "favorite-icon" }, [
                            _c("img", { attrs: { src: _vm.icon(val.mode) } })
                          ]),
                          _c("div", { staticClass: "favorite-info" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(val.name))
                            ]),
                            _c("span", { staticClass: "other" }, [
                              _vm._v(_vm._s(val.id) + " - " + _vm._s(val.other))
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "w100 secondary-color",
                      staticStyle: { "max-width": "550px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.openModal("add")
                        }
                      }
                    },
                    [_vm._v("Add to favorites")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: {
            title: _vm.modal.mode == "add" ? "Add to favorites" : "Edit"
          },
          model: {
            value: _vm.modal.modal_active,
            callback: function($$v) {
              _vm.$set(_vm.modal, "modal_active", $$v)
            },
            expression: "modal.modal_active"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-info" },
            [
              _c(
                "form-item",
                { attrs: { label: "Favorite type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Type" },
                      model: {
                        value: _vm.modal.selected_type,
                        callback: function($$v) {
                          _vm.$set(_vm.modal, "selected_type", $$v)
                        },
                        expression: "modal.selected_type"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Bus Stop", value: "bus" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Dart Station", value: "dart" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Luas Station", value: "luas" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }